<template>
	<v-expansion-panel>
		<slot name="name"></slot>
		<slot name="pages"></slot>
	</v-expansion-panel>
</template>
<script>
export default {
	name: "CourseEditorSidebarElement",

	computed: {},
	data() {
		return {};
	},
	methods: {}
};
</script>

<style></style>
